/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type TrainingType = 'fellowship' | 'internship';

export const TrainingType = {
    Fellowship: 'fellowship' as TrainingType,
    Internship: 'internship' as TrainingType
};

