import { Language } from '../shared/models/common.model';

export const constants: {
    defaultLanguage: Language;
    languages: Language[];
    publicLanguages: Language[];
    // recaptchaV3Sitekey?: string;
    // recaptchaV2Sitekey?: string;
} = {
    defaultLanguage: 'du',
    languages: ['du', 'nl', 'fr', 'en'],
    publicLanguages: ['nl', 'fr', 'en']
    // recaptchaV3Sitekey: null,
    // recaptchaV2Sitekey: null
};
