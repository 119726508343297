/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type ResearchType = 'mental-health-umcg' | 'incaso-covid19' | 'scaffold-feedback';

export const ResearchType = {
    MentalHealthUmcg: 'mental-health-umcg' as ResearchType,
    IncasoCovid19: 'incaso-covid19' as ResearchType,
    ScaffoldFeedback: 'scaffold-feedback' as ResearchType
};

