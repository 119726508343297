/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdminUserSupervisorLicenseReferencesInner } from './adminUserSupervisorLicenseReferencesInner';


/**
 * 
 */
export interface PartialAdminUserSupervisorLicense { 
    /**
     * 
     */
    id?: number;
    /**
     * 
     */
    status?: PartialAdminUserSupervisorLicense.StatusEnum;
    /**
     * 
     */
    references?: Array<AdminUserSupervisorLicenseReferencesInner>;
    /**
     * 
     */
    licenseFrom?: string;
    /**
     * 
     */
    licenseTo?: string;
    /**
     * 
     */
    licenseAccountId?: number;
    /**
     * 
     */
    licenseAccountName?: string;
}
export namespace PartialAdminUserSupervisorLicense {
    export type StatusEnum = 'active' | 'archived' | 'expired' | 'trial';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Archived: 'archived' as StatusEnum,
        Expired: 'expired' as StatusEnum,
        Trial: 'trial' as StatusEnum
    };
}


