/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LogbookValidateLogbookItemBody { 
    /**
     * 
     */
    id: number;
    /**
     * 
     */
    subscription_id: number;
    /**
     * 
     */
    type: string;
    /**
     * 
     */
    reject_feedback?: string;
    /**
     * 
     */
    reject_status?: LogbookValidateLogbookItemBody.RejectStatusEnum;
    /**
     * 
     */
    status: LogbookValidateLogbookItemBody.StatusEnum;
}
export namespace LogbookValidateLogbookItemBody {
    export type RejectStatusEnum = 'incorrect-hours' | 'incorrect-supervisor' | 'other';
    export const RejectStatusEnum = {
        IncorrectHours: 'incorrect-hours' as RejectStatusEnum,
        IncorrectSupervisor: 'incorrect-supervisor' as RejectStatusEnum,
        Other: 'other' as RejectStatusEnum
    };
    export type StatusEnum = 'requested' | 'approved' | 'rejected';
    export const StatusEnum = {
        Requested: 'requested' as StatusEnum,
        Approved: 'approved' as StatusEnum,
        Rejected: 'rejected' as StatusEnum
    };
}


