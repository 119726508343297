/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EduTodoSaveEduTodoBodyItemsInner } from './eduTodoSaveEduTodoBodyItemsInner';


export interface EduTodoSaveEduTodoBody { 
    /**
     * 
     */
    id?: string;
    /**
     * 
     */
    userid: string;
    /**
     * 
     */
    opleidingid: number;
    /**
     * 
     */
    competentieid: number;
    /**
     * 
     */
    title: string;
    /**
     * 
     */
    type: EduTodoSaveEduTodoBody.TypeEnum;
    /**
     * 
     */
    item_module: EduTodoSaveEduTodoBody.ItemModuleEnum;
    /**
     * 
     */
    periodiciteit: EduTodoSaveEduTodoBody.PeriodiciteitEnum;
    /**
     * 
     */
    aantal: number;
    /**
     * 
     */
    aantal_eenheid: EduTodoSaveEduTodoBody.AantalEenheidEnum;
    /**
     * 
     */
    periodiciteit_dag?: number;
    /**
     * 
     */
    periodiciteit_maand?: number;
    /**
     * 
     */
    periodiciteit_lnk_edu_todo_id?: string;
    /**
     * 
     */
    periodiciteit_moment?: EduTodoSaveEduTodoBody.PeriodiciteitMomentEnum;
    /**
     * 
     */
    items?: Array<EduTodoSaveEduTodoBodyItemsInner>;
    /**
     * 
     */
    minopleidingsjaar?: number;
    /**
     * 
     */
    maxopleidingsjaar?: number;
    /**
     * 
     */
    link?: string;
    /**
     * 
     */
    attendance_ref?: string;
    /**
     * 
     */
    scientific_period?: boolean;
}
export namespace EduTodoSaveEduTodoBody {
    export type TypeEnum = 'item' | 'attendance' | 'exercise' | 'exam' | 'link';
    export const TypeEnum = {
        Item: 'item' as TypeEnum,
        Attendance: 'attendance' as TypeEnum,
        Exercise: 'exercise' as TypeEnum,
        Exam: 'exam' as TypeEnum,
        Link: 'link' as TypeEnum
    };
    export type ItemModuleEnum = 'assessments' | 'internships' | 'logbook' | 'portfolio' | 'reflections' | 'training' | 'opleiding' | 'goals' | 'exercises' | 'leeragenda' | 'learning-agenda' | 'certificate' | 'cases' | 'any';
    export const ItemModuleEnum = {
        Assessments: 'assessments' as ItemModuleEnum,
        Internships: 'internships' as ItemModuleEnum,
        Logbook: 'logbook' as ItemModuleEnum,
        Portfolio: 'portfolio' as ItemModuleEnum,
        Reflections: 'reflections' as ItemModuleEnum,
        Training: 'training' as ItemModuleEnum,
        Opleiding: 'opleiding' as ItemModuleEnum,
        Goals: 'goals' as ItemModuleEnum,
        Exercises: 'exercises' as ItemModuleEnum,
        Leeragenda: 'leeragenda' as ItemModuleEnum,
        LearningAgenda: 'learning-agenda' as ItemModuleEnum,
        Certificate: 'certificate' as ItemModuleEnum,
        Cases: 'cases' as ItemModuleEnum,
        Any: 'any' as ItemModuleEnum
    };
    export type PeriodiciteitEnum = 'stageperiode' | 'jaarlijks' | 'todo' | 'opleiding' | 'maandelijks' | 'opleidingsjaar';
    export const PeriodiciteitEnum = {
        Stageperiode: 'stageperiode' as PeriodiciteitEnum,
        Jaarlijks: 'jaarlijks' as PeriodiciteitEnum,
        Todo: 'todo' as PeriodiciteitEnum,
        Opleiding: 'opleiding' as PeriodiciteitEnum,
        Maandelijks: 'maandelijks' as PeriodiciteitEnum,
        Opleidingsjaar: 'opleidingsjaar' as PeriodiciteitEnum
    };
    export type AantalEenheidEnum = 'item' | 'hour' | 'credit';
    export const AantalEenheidEnum = {
        Item: 'item' as AantalEenheidEnum,
        Hour: 'hour' as AantalEenheidEnum,
        Credit: 'credit' as AantalEenheidEnum
    };
    export type PeriodiciteitMomentEnum = 'begin' | 'middle' | 'end';
    export const PeriodiciteitMomentEnum = {
        Begin: 'begin' as PeriodiciteitMomentEnum,
        Middle: 'middle' as PeriodiciteitMomentEnum,
        End: 'end' as PeriodiciteitMomentEnum
    };
}


