/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type ExportTypeEnum = 'annual-report' | 'training-report';

export const ExportTypeEnum = {
    AnnualReport: 'annual-report' as ExportTypeEnum,
    TrainingReport: 'training-report' as ExportTypeEnum
};

