/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganisationType } from './organisationType';


/**
 * 
 */
export interface Organisation { 
    /**
     * 
     */
    id: number;
    /**
     * 
     */
    name: string;
    /**
     * 
     */
    code?: string;
    /**
     * 
     */
    nameCode?: string;
    type: OrganisationType;
    /**
     * 
     */
    locationReferences?: boolean;
    /**
     * 
     */
    supervisorTraining?: Organisation.SupervisorTrainingEnum;
}
export namespace Organisation {
    export type SupervisorTrainingEnum = 'trainingGroup' | 'training';
    export const SupervisorTrainingEnum = {
        TrainingGroup: 'trainingGroup' as SupervisorTrainingEnum,
        Training: 'training' as SupervisorTrainingEnum
    };
}


