/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserUpdateCvBlocksBody { 
    /**
     * 
     */
    cvBlocks: Array<UserUpdateCvBlocksBody.CvBlocksEnum>;
}
export namespace UserUpdateCvBlocksBody {
    export type CvBlocksEnum = 'introduction' | 'work-experience' | 'diploma' | 'experience' | 'language-skills' | 'talents' | 'interests' | 'volunteer-work' | 'course' | 'educational-activity' | 'thesis' | 'membership' | 'publication' | 'presentation' | 'congress' | 'certificate' | 'skills';
    export const CvBlocksEnum = {
        Introduction: 'introduction' as CvBlocksEnum,
        WorkExperience: 'work-experience' as CvBlocksEnum,
        Diploma: 'diploma' as CvBlocksEnum,
        Experience: 'experience' as CvBlocksEnum,
        LanguageSkills: 'language-skills' as CvBlocksEnum,
        Talents: 'talents' as CvBlocksEnum,
        Interests: 'interests' as CvBlocksEnum,
        VolunteerWork: 'volunteer-work' as CvBlocksEnum,
        Course: 'course' as CvBlocksEnum,
        EducationalActivity: 'educational-activity' as CvBlocksEnum,
        Thesis: 'thesis' as CvBlocksEnum,
        Membership: 'membership' as CvBlocksEnum,
        Publication: 'publication' as CvBlocksEnum,
        Presentation: 'presentation' as CvBlocksEnum,
        Congress: 'congress' as CvBlocksEnum,
        Certificate: 'certificate' as CvBlocksEnum,
        Skills: 'skills' as CvBlocksEnum
    };
}


