/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExportTypeEnum } from './exportTypeEnum';
import { SingleExportSignature } from './singleExportSignature';
import { PdfQueueStatus } from './pdfQueueStatus';
import { SingleExportAttachment } from './singleExportAttachment';
import { ExportSignatureStatus } from './exportSignatureStatus';
import { SingleExportPeriodsInner } from './singleExportPeriodsInner';
import { SingleExportLinkedItem } from './singleExportLinkedItem';


/**
 * 
 */
export interface SingleExport { 
    /**
     * 
     */
    id?: string;
    type?: ExportTypeEnum;
    /**
     * 
     */
    internshipYear: number;
    /**
     * 
     */
    isCurrentInternshipYear?: boolean;
    status?: ExportSignatureStatus;
    /**
     * 
     */
    subscriptionId: number;
    /**
     * 
     */
    periods: Array<SingleExportPeriodsInner>;
    /**
     * 
     */
    pageOrientation?: SingleExport.PageOrientationEnum;
    /**
     * 
     */
    periodicity?: string;
    /**
     * 
     */
    signatureRequirements?: Array<string>;
    /**
     * 
     */
    signatureRequirementsRotation?: Array<string>;
    /**
     * 
     */
    training: string;
    /**
     * 
     */
    trainingId?: number;
    /**
     * 
     */
    htmlUrl?: string;
    /**
     * 
     */
    generateTS?: string;
    /**
     * 
     */
    signatures?: Array<SingleExportSignature>;
    /**
     * 
     */
    attachments?: Array<SingleExportAttachment>;
    /**
     * 
     */
    items?: Array<SingleExportLinkedItem>;
    pdfStatus?: PdfQueueStatus;
}
export namespace SingleExport {
    export type PageOrientationEnum = 'landscape' | 'portrait';
    export const PageOrientationEnum = {
        Landscape: 'landscape' as PageOrientationEnum,
        Portrait: 'portrait' as PageOrientationEnum
    };
}


