/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface TechniqueItem { 
    /**
     * 
     */
    id: number;
    /**
     * 
     */
    type: string;
    /**
     * 
     */
    discipline_id: number;
    /**
     * 
     */
    category_id: number;
    /**
     * 
     */
    technique_id: number;
    /**
     * 
     */
    qty: number;
    /**
     * 
     */
    independency_level: string;
    /**
     * 
     */
    comment: string;
    /**
     * 
     */
    create_ts: string;
    /**
     * 
     */
    create_username: string;
    /**
     * 
     */
    edit_ts: string;
    /**
     * 
     */
    edit_username: string;
}

