import { Inject, Injectable, computed, inject, signal } from '@angular/core';
import { DefaultService } from '../../utils/api';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from './config.service';
import { constants } from '../../utils/constants';
import { lastValueFrom } from 'rxjs';

// import angular locales
import '@angular/common/locales/global/nl-BE';
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/en-GB';

// localize dayjs
import 'dayjs/locale/nl-be';
import 'dayjs/locale/fr';
import 'dayjs/locale/en-gb';

// extend dayjs
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import calendar from 'dayjs/plugin/calendar';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';

import { Language } from '../models/common.model';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    // injections
    defaultService = inject(DefaultService);
    configService = inject(ConfigService);

    // signals
    translations = signal<Record<string, string | null | undefined>>({});
    language = signal<Language>(null);
    urlLanguage = computed(() => {
        switch (this.language()) {
            case 'du':
                return 'nl';
            case 'nl':
                return 'nl';
            case 'fr':
                return 'fr';
            case 'en':
                return 'en';
        }
    });

    constructor(@Inject(DOCUMENT) private document: Document) {}

    public async init(language?: Language): Promise<void> {
        language = language || this.getLanguage();
        if (language && !constants.languages.includes(language)) {
            language = constants.defaultLanguage;
        }
        this.setLanguage(language);
        this.initDayjs();
        return lastValueFrom(this.defaultService.angularLanguageGetAllStringForCurrentLanguage()).then((response) => {
            this.translations.set(response as Record<string, string | null | undefined>);
        });
    }

    public getTranslation(key: string): string {
        if (!key) return key;
        key = key.toLowerCase();
        if (this.translations()[key]) {
            return this.translations()[key];
        } else {
            if (this.configService.serverConfig().enableStringUpdate) {
                this.defaultService
                    .angularLanguageAddNewString({ angularLanguageAddNewStringBody: { code: key } })
                    .subscribe();
            }
            return key;
        }
    }

    public getLanguage(): Language {
        let browserLang = null;
        let localstorageLang = null;
        let urlLang = null;
        if (navigator) browserLang = navigator.language || navigator['userLanguage'];
        if (browserLang && !constants.languages.includes(browserLang?.substring(0, 2))) {
            browserLang = null;
        } else browserLang = browserLang.substring(0, 2);
        if (localStorage.getItem('language')) {
            localstorageLang = localStorage.getItem('language');
        }
        if (localstorageLang && !constants.languages.includes(localstorageLang)) {
            localstorageLang = null;
        }
        const path = `${window.location.pathname}/`;
        constants.languages.forEach((lang) => {
            if (path.startsWith(`/${lang}/`)) {
                urlLang = lang;
            }
        });
        return (
            urlLang ||
            this.language() ||
            localstorageLang ||
            window['language'] ||
            browserLang ||
            constants.defaultLanguage
        );
    }

    public getLocale(language?: Language): string {
        language = language || this.getLanguage();
        switch (language) {
            case 'nl':
                return 'nl-be';
            case 'du':
                return 'nl-be';
            case 'fr':
                return 'fr';
            case 'en':
                return 'en-gb';
        }
    }

    private setLanguage(language: Language) {
        window['language'] = language;
        this.document.documentElement.lang = this.getIsoLanguageCode(language);
        this.language.set(language);
    }

    public getIsoLanguageCode(language: Language) {
        switch (language) {
            case 'du':
                return 'nl';
            default:
                return language;
        }
    }

    private initDayjs() {
        dayjs.extend(customParseFormat);
        dayjs.extend(calendar);
        dayjs.extend(isSameOrBefore);
        dayjs.extend(isSameOrAfter);
        dayjs.extend(utc);
        dayjs.locale(this.getLocale().toLowerCase());
    }
}
