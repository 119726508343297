/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type TodoAmountUnit = 'item' | 'hour' | 'credit';

export const TodoAmountUnit = {
    Item: 'item' as TodoAmountUnit,
    Hour: 'hour' as TodoAmountUnit,
    Credit: 'credit' as TodoAmountUnit
};

