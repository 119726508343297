/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type AbsenceTypes = 'illness' | 'training' | 'other';

export const AbsenceTypes = {
    Illness: 'illness' as AbsenceTypes,
    Training: 'training' as AbsenceTypes,
    Other: 'other' as AbsenceTypes
};

