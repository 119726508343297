/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContactSaveContactBody { 
    /**
     * 
     */
    name: string;
    /**
     * 
     */
    org?: string;
    /**
     * 
     */
    email: string;
    /**
     * 
     */
    phone?: string;
    /**
     * 
     */
    message: string;
    /**
     * 
     */
    source: string;
    /**
     * 
     */
    type: string;
    /**
     * 
     */
    referrer?: string;
}

