/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type ExportSignatureStatus = 'rejected' | 'signed' | 'pending' | 'created';

export const ExportSignatureStatus = {
    Rejected: 'rejected' as ExportSignatureStatus,
    Signed: 'signed' as ExportSignatureStatus,
    Pending: 'pending' as ExportSignatureStatus,
    Created: 'created' as ExportSignatureStatus
};

