/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserUpdateCvTextBlockBody { 
    /**
     * 
     */
    type: UserUpdateCvTextBlockBody.TypeEnum;
    /**
     * 
     */
    text: string;
}
export namespace UserUpdateCvTextBlockBody {
    export type TypeEnum = 'interests' | 'experience' | 'talents' | 'introduction';
    export const TypeEnum = {
        Interests: 'interests' as TypeEnum,
        Experience: 'experience' as TypeEnum,
        Talents: 'talents' as TypeEnum,
        Introduction: 'introduction' as TypeEnum
    };
}


