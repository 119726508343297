/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type LogbookValidationStatus = 'requested' | 'approved' | 'rejected';

export const LogbookValidationStatus = {
    Requested: 'requested' as LogbookValidationStatus,
    Approved: 'approved' as LogbookValidationStatus,
    Rejected: 'rejected' as LogbookValidationStatus
};

