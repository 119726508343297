/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type SurveyType = 'gp-hospital-evaluation' | 'gp-practice-evaluation' | 'gp-seminar-evaluation' | 'internship-evaluation-interuniversity' | 'internship-evaluation-nursing-hbo' | 'medbook-evaluation-interuniversity' | 'supervision-evaluation-nursing-hbo';

export const SurveyType = {
    GpHospitalEvaluation: 'gp-hospital-evaluation' as SurveyType,
    GpPracticeEvaluation: 'gp-practice-evaluation' as SurveyType,
    GpSeminarEvaluation: 'gp-seminar-evaluation' as SurveyType,
    InternshipEvaluationInteruniversity: 'internship-evaluation-interuniversity' as SurveyType,
    InternshipEvaluationNursingHbo: 'internship-evaluation-nursing-hbo' as SurveyType,
    MedbookEvaluationInteruniversity: 'medbook-evaluation-interuniversity' as SurveyType,
    SupervisionEvaluationNursingHbo: 'supervision-evaluation-nursing-hbo' as SurveyType
};

