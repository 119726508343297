/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface GoalTypeInfo { 
    /**
     * 
     */
    code: string;
    /**
     * 
     */
    title: string;
    /**
     * 
     */
    needs_validation: boolean;
    /**
     * 
     */
    can_create_assistant: boolean;
    /**
     * 
     */
    can_create_supervisor: boolean;
    /**
     * 
     */
    can_create_staff: boolean;
    /**
     * 
     */
    can_alter_status_assistant: boolean;
}

