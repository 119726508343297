/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type OrganisationType = 'organisation' | 'university';

export const OrganisationType = {
    Organisation: 'organisation' as OrganisationType,
    University: 'university' as OrganisationType
};

