/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type CvBlockEnum = 'introduction' | 'work-experience' | 'diploma' | 'experience' | 'language-skills' | 'talents' | 'interests' | 'volunteer-work' | 'course' | 'educational-activity' | 'thesis' | 'membership' | 'publication' | 'presentation' | 'congress' | 'certificate' | 'skills';

export const CvBlockEnum = {
    Introduction: 'introduction' as CvBlockEnum,
    WorkExperience: 'work-experience' as CvBlockEnum,
    Diploma: 'diploma' as CvBlockEnum,
    Experience: 'experience' as CvBlockEnum,
    LanguageSkills: 'language-skills' as CvBlockEnum,
    Talents: 'talents' as CvBlockEnum,
    Interests: 'interests' as CvBlockEnum,
    VolunteerWork: 'volunteer-work' as CvBlockEnum,
    Course: 'course' as CvBlockEnum,
    EducationalActivity: 'educational-activity' as CvBlockEnum,
    Thesis: 'thesis' as CvBlockEnum,
    Membership: 'membership' as CvBlockEnum,
    Publication: 'publication' as CvBlockEnum,
    Presentation: 'presentation' as CvBlockEnum,
    Congress: 'congress' as CvBlockEnum,
    Certificate: 'certificate' as CvBlockEnum,
    Skills: 'skills' as CvBlockEnum
};

