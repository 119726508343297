/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface TrainingPlanCourse { 
    /**
     * 
     */
    id: number;
    /**
     * 
     */
    course_id: number;
    /**
     * 
     */
    type: string;
    /**
     * 
     */
    refcode: string;
    /**
     * 
     */
    name: string;
    /**
     * 
     */
    description: string;
    /**
     * 
     */
    period: string;
    /**
     * 
     */
    hospital: string;
    /**
     * 
     */
    credits: number;
    /**
     * 
     */
    actions: Array<string>;
    /**
     * 
     */
    is_archived: boolean;
    /**
     * 
     */
    is_current: boolean;
    /**
     * 
     */
    is_readonly: boolean;
    /**
     * 
     */
    can_have_multi_occurences: boolean;
    /**
     * 
     */
    is_auto_add: boolean;
    /**
     * 
     */
    sequence: number;
    /**
     * 
     */
    status: string;
}

