/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type TrainingPlanType = 'matrix' | 'matrix_courses' | 'matrix_courses_epa' | 'simple';

export const TrainingPlanType = {
    Matrix: 'matrix' as TrainingPlanType,
    MatrixCourses: 'matrix_courses' as TrainingPlanType,
    MatrixCoursesEpa: 'matrix_courses_epa' as TrainingPlanType,
    Simple: 'simple' as TrainingPlanType
};

