/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type ResearchTypeStatus = 'saved' | 'draft';

export const ResearchTypeStatus = {
    Saved: 'saved' as ResearchTypeStatus,
    Draft: 'draft' as ResearchTypeStatus
};

