/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type SubscriptionType = 'assistant' | 'pro' | 'staff' | 'supervisor' | 'admin';

export const SubscriptionType = {
    Assistant: 'assistant' as SubscriptionType,
    Pro: 'pro' as SubscriptionType,
    Staff: 'staff' as SubscriptionType,
    Supervisor: 'supervisor' as SubscriptionType,
    Admin: 'admin' as SubscriptionType
};

