/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AnnualReportSignOrRejectBody { 
    /**
     * 
     */
    comment?: string;
    /**
     * 
     */
    status: AnnualReportSignOrRejectBody.StatusEnum;
}
export namespace AnnualReportSignOrRejectBody {
    export type StatusEnum = 'rejected' | 'signed' | 'pending' | 'created';
    export const StatusEnum = {
        Rejected: 'rejected' as StatusEnum,
        Signed: 'signed' as StatusEnum,
        Pending: 'pending' as StatusEnum,
        Created: 'created' as StatusEnum
    };
}


