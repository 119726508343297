/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type ModuleType = 'assessments' | 'internships' | 'logbook' | 'portfolio' | 'reflections' | 'training' | 'opleiding' | 'goals' | 'exercises' | 'leeragenda' | 'learning-agenda' | 'certificate' | 'cases' | 'any';

export const ModuleType = {
    Assessments: 'assessments' as ModuleType,
    Internships: 'internships' as ModuleType,
    Logbook: 'logbook' as ModuleType,
    Portfolio: 'portfolio' as ModuleType,
    Reflections: 'reflections' as ModuleType,
    Training: 'training' as ModuleType,
    Opleiding: 'opleiding' as ModuleType,
    Goals: 'goals' as ModuleType,
    Exercises: 'exercises' as ModuleType,
    Leeragenda: 'leeragenda' as ModuleType,
    LearningAgenda: 'learning-agenda' as ModuleType,
    Certificate: 'certificate' as ModuleType,
    Cases: 'cases' as ModuleType,
    Any: 'any' as ModuleType
};

