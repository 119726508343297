/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AnnualReportGenerateBodyItemsInner { 
    /**
     * 
     */
    id: number;
    /**
     * 
     */
    code: string;
    /**
     * 
     */
    module: AnnualReportGenerateBodyItemsInner.ModuleEnum;
    /**
     * 
     */
    sequence: number;
}
export namespace AnnualReportGenerateBodyItemsInner {
    export type ModuleEnum = 'assessments' | 'internships' | 'logbook' | 'portfolio' | 'reflections' | 'training' | 'opleiding' | 'goals' | 'exercises' | 'leeragenda' | 'learning-agenda' | 'certificate' | 'cases' | 'any';
    export const ModuleEnum = {
        Assessments: 'assessments' as ModuleEnum,
        Internships: 'internships' as ModuleEnum,
        Logbook: 'logbook' as ModuleEnum,
        Portfolio: 'portfolio' as ModuleEnum,
        Reflections: 'reflections' as ModuleEnum,
        Training: 'training' as ModuleEnum,
        Opleiding: 'opleiding' as ModuleEnum,
        Goals: 'goals' as ModuleEnum,
        Exercises: 'exercises' as ModuleEnum,
        Leeragenda: 'leeragenda' as ModuleEnum,
        LearningAgenda: 'learning-agenda' as ModuleEnum,
        Certificate: 'certificate' as ModuleEnum,
        Cases: 'cases' as ModuleEnum,
        Any: 'any' as ModuleEnum
    };
}


