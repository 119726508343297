/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type PortfolioResourceType = 'subType' | 'roleCode' | 'statusCode' | 'passedCode';

export const PortfolioResourceType = {
    SubType: 'subType' as PortfolioResourceType,
    RoleCode: 'roleCode' as PortfolioResourceType,
    StatusCode: 'statusCode' as PortfolioResourceType,
    PassedCode: 'passedCode' as PortfolioResourceType
};

