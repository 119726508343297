/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AnnualReportGetPdfUrl200Response { 
    /**
     * 
     */
    pdfStatus?: AnnualReportGetPdfUrl200Response.PdfStatusEnum;
    /**
     * 
     */
    positionInQueue?: number;
    /**
     * 
     */
    blobUrl?: string;
}
export namespace AnnualReportGetPdfUrl200Response {
    export type PdfStatusEnum = 'queued' | 'generating' | 'done' | 'error';
    export const PdfStatusEnum = {
        Queued: 'queued' as PdfStatusEnum,
        Generating: 'generating' as PdfStatusEnum,
        Done: 'done' as PdfStatusEnum,
        Error: 'error' as PdfStatusEnum
    };
}


