/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type AssistantTypeEnum = 'GP' | 'intern' | 'student' | 'assistant';

export const AssistantTypeEnum = {
    Gp: 'GP' as AssistantTypeEnum,
    Intern: 'intern' as AssistantTypeEnum,
    Student: 'student' as AssistantTypeEnum,
    Assistant: 'assistant' as AssistantTypeEnum
};

