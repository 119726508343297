import { Injectable, inject, signal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { DefaultService } from '../../utils/api/api/default.service';
import { User } from '../../utils/api/model/user';
// import { UsersLoginBody, UsersResetPasswordBody, FirstLoginUserPayload, LoginResponse } from '../../utils/api';
import { HelpersService } from './helpers.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    // injections
    defaultService = inject(DefaultService);
    helpersService = inject(HelpersService);
    router = inject(Router);

    // signals
    user = signal<User>(null);
    loginData = signal<{ email: string; password?: string }>(null);
    mfaSetup = signal<{ url: string; secret: string }>(null);
    localStorageOverviewConfigPerSubscriptionId = signal<Record<string, Record<string, any>>>(null);

    public async init() {
        this.initLocalStorageOverviewConfigPerSubscriptionId();
    }

    public refreshUser() {
        return lastValueFrom(this.defaultService.usersGetUserinfo()).then((res) => this.user.set(res));
    }

    initLocalStorageOverviewConfigPerSubscriptionId() {
        const result = localStorage.getItem('localStorageOverviewConfigPerSubscriptionId');
        if (result) {
            this.localStorageOverviewConfigPerSubscriptionId.set(JSON.parse(result));
        } else {
            if (!this.user().subscriptionId) return;
            this.localStorageOverviewConfigPerSubscriptionId.set({ [this.user().subscriptionId]: {} });
        }
    }

    public logout(): void {
        // this.defaultService.usersLogout().subscribe((next) => {
        //     window.location.href = 'login';
        // });
        window.location.href = `fuseAction.cfm?module=users&subpage=logout&sid=${this.user().subscriptionId}`;
    }

    public onUnauthorized(): void {
        let to = `${window.location.pathname.replace(/^\/+/g, '')}`;
        if (to) {
            to = `?to=${to}`;
        } else to = '';
        window.location.href = `login${to}`;
    }

    // public afterAuthCall(
    //     next: LoginResponse,
    //     FORM: UsersLoginBody & UsersResetPasswordBody & FirstLoginUserPayload
    // ): void {
    //     switch (next.result) {
    //         case 'login':
    //             this.loginRedirect();
    //             break;
    //         case 'setup-mfa':
    //             if ('mfaSetup' in next) {
    //                 this.mfaSetup.set(next.mfaSetup);
    //                 this.loginData.set(FORM);
    //                 this.router.navigate(['setup-multifactor-authentication'], {
    //                     queryParamsHandling: 'preserve'
    //                 });
    //             }
    //             break;
    //         case 'prompt-mfa':
    //             this.loginData.set(FORM);
    //             this.router.navigate(['multifactor-authentication'], {
    //                 queryParamsHandling: 'preserve'
    //             });
    //             break;
    //     }
    // }

    public loginRedirect(): void {
        let to = this.helpersService.getParam('to') || '';
        to = to.replace(/^\W*/g, '');
        to = `/${to}`;
        if (to === '/') to = null;
        window.location.href = to || '/';
    }

    public setLocalStorageOverviewConfigPerSubscriptionId(key: `assistantOverview_${string}`, value: any) {
        if (!this.user().subscriptionId) return;
        const result = this.localStorageOverviewConfigPerSubscriptionId();
        result[this.user().subscriptionId] = { ...result[this.user().subscriptionId], [key]: value };
        this.localStorageOverviewConfigPerSubscriptionId.set(result);
        localStorage.setItem('localStorageOverviewConfigPerSubscriptionId', JSON.stringify(result));
    }
}
