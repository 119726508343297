/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type CvVisibilityEnum = 'private' | 'contacts';

export const CvVisibilityEnum = {
    Private: 'private' as CvVisibilityEnum,
    Contacts: 'contacts' as CvVisibilityEnum
};

