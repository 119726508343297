/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type TodoStatus = 'ongoing' | 'inapplicable' | 'completed';

export const TodoStatus = {
    Ongoing: 'ongoing' as TodoStatus,
    Inapplicable: 'inapplicable' as TodoStatus,
    Completed: 'completed' as TodoStatus
};

