/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type PdfQueueStatus = 'queued' | 'generating' | 'done' | 'error';

export const PdfQueueStatus = {
    Queued: 'queued' as PdfQueueStatus,
    Generating: 'generating' as PdfQueueStatus,
    Done: 'done' as PdfQueueStatus,
    Error: 'error' as PdfQueueStatus
};

