/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type TimeIntervalUnit = 'days' | 'weeks' | 'months' | 'years';

export const TimeIntervalUnit = {
    Days: 'days' as TimeIntervalUnit,
    Weeks: 'weeks' as TimeIntervalUnit,
    Months: 'months' as TimeIntervalUnit,
    Years: 'years' as TimeIntervalUnit
};

