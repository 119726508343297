/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type SubscriptionReferenceTypeEnum = 'riziv' | 'big' | 'member' | 'employee';

export const SubscriptionReferenceTypeEnum = {
    Riziv: 'riziv' as SubscriptionReferenceTypeEnum,
    Big: 'big' as SubscriptionReferenceTypeEnum,
    Member: 'member' as SubscriptionReferenceTypeEnum,
    Employee: 'employee' as SubscriptionReferenceTypeEnum
};

