/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface UserNotification { 
    /**
     * 
     */
    type: UserNotification.TypeEnum;
    /**
     * 
     */
    severity?: UserNotification.SeverityEnum;
    /**
     * 
     */
    action?: UserNotification.ActionEnum;
    /**
     * 
     */
    days?: number;
    /**
     * 
     */
    date?: string;
}
export namespace UserNotification {
    export type TypeEnum = 'expiring' | 'trial' | 'archived' | 'expired' | 'external-webshop' | 'trainingplan';
    export const TypeEnum = {
        Expiring: 'expiring' as TypeEnum,
        Trial: 'trial' as TypeEnum,
        Archived: 'archived' as TypeEnum,
        Expired: 'expired' as TypeEnum,
        ExternalWebshop: 'external-webshop' as TypeEnum,
        Trainingplan: 'trainingplan' as TypeEnum
    };
    export type SeverityEnum = 'low' | 'high';
    export const SeverityEnum = {
        Low: 'low' as SeverityEnum,
        High: 'high' as SeverityEnum
    };
    export type ActionEnum = 'add' | 'detail';
    export const ActionEnum = {
        Add: 'add' as ActionEnum,
        Detail: 'detail' as ActionEnum
    };
}


