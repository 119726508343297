/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type TodoType = 'item' | 'attendance' | 'exercise' | 'exam' | 'link';

export const TodoType = {
    Item: 'item' as TodoType,
    Attendance: 'attendance' as TodoType,
    Exercise: 'exercise' as TodoType,
    Exam: 'exam' as TodoType,
    Link: 'link' as TodoType
};

