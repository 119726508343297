/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type TimeFrameTypes = 'past' | 'current' | 'future';

export const TimeFrameTypes = {
    Past: 'past' as TimeFrameTypes,
    Current: 'current' as TimeFrameTypes,
    Future: 'future' as TimeFrameTypes
};

