/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type LogbookValidationRejectStatus = 'incorrect-hours' | 'incorrect-supervisor' | 'other';

export const LogbookValidationRejectStatus = {
    IncorrectHours: 'incorrect-hours' as LogbookValidationRejectStatus,
    IncorrectSupervisor: 'incorrect-supervisor' as LogbookValidationRejectStatus,
    Other: 'other' as LogbookValidationRejectStatus
};

