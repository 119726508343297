import { Injectable, Injector, computed, inject, signal } from '@angular/core';
import { ServerConfig, RouteConfig } from '../models/config.model';
import { AuthService } from './auth.service';
import { User } from '../../utils/api';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    // injections
    injector = inject(Injector);

    // signals
    routeConfig = signal<RouteConfig>(null);
    serverConfig = signal<ServerConfig>(null);
    adminOverviewConfig = signal<Record<string, any>>({});
}
